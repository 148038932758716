import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { login as apiLogin, logout as apiLogout, checkAuth } from '../../api/auth';
import { setError, clearError } from './errorSlice';
import { clearClients } from './cliSlice';

// Fonction pour déterminer la taille de l'écran
const getInitialDisplay = () => {
  return window.innerWidth <= 768 ? 'mobile' : 'pc';
};

const initialState = {
  isAuthenticated: false,
  userInfo: null,
  loading: false,
  display: getInitialDisplay(),
};

export const login = createAsyncThunk('auth/login', async (credentials, { dispatch, rejectWithValue }) => {
  try {
    const response = await apiLogin(credentials);
    if (response.data.success) {
      dispatch(clearError());
      return response.data.userInfo;
    } else {
      const customErrorMessage = response.data.message || 'Login failed';
      dispatch(setError(customErrorMessage));
      return rejectWithValue(customErrorMessage);
    }
  } catch (error) {
    const customErrorMessage = error.response?.data?.message || error.message;
    dispatch(setError(customErrorMessage));
    return rejectWithValue(customErrorMessage);
  }
});

export const logout = createAsyncThunk('auth/logout', async (_, { dispatch }) => {
  try {
    document.cookie = 'authToken=; Max-Age=0; path=/; domain=localhost; secure; SameSite=None;';
    document.cookie = 'refreshToken=; Max-Age=0; path=/; domain=localhost; secure; SameSite=None;';
    await apiLogout();
    dispatch(clearError()); // Nettoyer les erreurs lors de la déconnexion
    dispatch(clearClients());
  } catch (error) {
    dispatch(setError(error.message)); // Gérer les erreurs de déconnexion
  }
});

export const checkAuthentication = createAsyncThunk(
  'auth/checkAuth',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState();
      if (!state.auth.isAuthenticated) {
        const errorMessage = 'User is not authenticated';
        dispatch(setError(errorMessage)); // Envoie l'erreur au store Redux
        return Promise.reject(errorMessage);
      }
      const isAuthenticated = await checkAuth();
      if (isAuthenticated) {
        dispatch(clearError()); // Nettoie les erreurs si la vérification réussit
        return isAuthenticated.userInfo;
      } else {
        throw new Error('Not authenticated');
      }
    } catch (error) {
      dispatch(setError(error.message)); // Gère les erreurs via Redux
      return Promise.reject(error.message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetState: () => initialState,
    setDisplay: (state, action) => {
      state.display = action.payload; // Met à jour la valeur de display
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.userInfo = action.payload;
        state.loading = false;
      })
      .addCase(login.rejected, (state) => {
        state.isAuthenticated = false;
        state.userInfo = null;
        state.loading = false;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.userInfo = null;
        state.loading = false;
      })
      .addCase(checkAuthentication.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.userInfo = action.payload;
        state.loading = false;
      })
      .addCase(checkAuthentication.rejected, (state) => {
        state.isAuthenticated = false;
        state.userInfo = null;
        state.loading = false;
      });
  },
});

export const { resetState, setDisplay } = authSlice.actions;
export default authSlice.reducer;