import axios from 'axios';
import { redirectToLogin } from '../utils/navigation';
import { logout } from '../store/slices/authSlice';
import { setError } from '../store/slices/errorSlice'; 

let store;

export const setStore = (_store) => {
  store = _store;
};

let baseURL;

if (window.location.hostname === 'localhost') {
  baseURL = 'https://localhost:443/api/';
} else {
  baseURL = 'https://gestion.syscab-reseaux.fr/api/';
}

const instance = axios.create({
  baseURL,
  withCredentials: true, // Assure l'envoi des cookies JWT avec les requêtes
});

// Ajouter un interceptor pour les réponses d'erreur
instance.interceptors.response.use(
  response => response,
  error => {
    const errorMessage = error.response?.data?.message || 'Une erreur s\'est produite';

    // Vérifiez que le store est disponible avant d'envoyer une action
    if (store) {
      store.dispatch(setError(errorMessage));
    }

    // Rediriger vers la page de login si le statut est 401 et le message d'erreur indique que le token est expiré
    if (error.response?.status === 401 && (errorMessage.includes('Token expiré') || errorMessage.includes('Token invalide') || errorMessage.includes('Aucun token fourni'))) {
      if (store) {
        store.dispatch(logout()); // Dispatcher l'action logout pour réinitialiser l'état d'authentification
      }
      redirectToLogin();
    }

    // Rejeter la promesse avec l'erreur
    return Promise.reject(error);
  }
);

export default instance;
