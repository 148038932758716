import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCli, fetchCliById, createClient, updateClientById } from '../../api/cli';

// Async thunks pour les appels API
export const fetchAllClients = createAsyncThunk('client/fetchAllClients', async () => {
  const response = await fetchCli();
  return response.data.cli; // Assurez-vous que la structure de la réponse correspond
});

export const fetchClientById = createAsyncThunk('client/fetchClientById', async (id) => {
  const response = await fetchCliById(id);
  return response.cli; // Assurez-vous que la structure de la réponse correspond
});

export const updateClientByIdAsync = createAsyncThunk('client/updateClientById', async ({ id, clientData }) => {
  const response = await updateClientById(id, clientData);
  return response;
});

// Slice Redux
const cliSlice = createSlice({
  name: 'client',
  initialState: {
    clients: [], // Pour stocker la liste de tous les clients
    selectedClient: null, // Pour stocker les détails du client sélectionné
    loading: false,
    error: null,
  },
  reducers: {
    clearSelectedClient: (state) => {
      state.selectedClient = null;
    },
    clearClients: (state) => {
      state.clients = [];
      state.selectedClient = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchAllClients
      .addCase(fetchAllClients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllClients.fulfilled, (state, action) => {
        state.loading = false;
        state.clients = action.payload;
      })
      .addCase(fetchAllClients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // fetchClientById
      .addCase(fetchClientById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClientById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedClient = action.payload;
      })
      .addCase(fetchClientById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // updateClientByIdAsync
      .addCase(updateClientByIdAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateClientByIdAsync.fulfilled, (state, action) => {
        state.loading = false;
        // Mettre à jour le client dans l'état si nécessaire
        if (state.selectedClient && state.selectedClient.CLI_id === action.payload.CLI_id) {
          state.selectedClient = action.payload;
        }
      })
      .addCase(updateClientByIdAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearSelectedClient, clearClients } = cliSlice.actions;
export default cliSlice.reducer;