import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/slices/authSlice';
import LoadingSpinner from '../shared/LoadingSpinner';

function Logout() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const performLogout = async () => {
      try {
        await dispatch(logout()).unwrap(); // utilisez l'action de déconnexion de Redux
      } catch (error) {
        // console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };
    performLogout();
  }, [dispatch]); // ajoutez dispatch à la liste des dépendances

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return null;
}

export default Logout;