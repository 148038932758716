import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card, Button } from 'react-bootstrap';

const WPFormSuccess = () => {
  const { TIK_num } = useParams();

  return (
    <Container className="mt-5">
      <Card className="text-center">
        <Card.Header as="h1">Merci !</Card.Header>
        <Card.Body>
          <Card.Title>Votre ticket a été créé avec succès !</Card.Title>
          <Card.Text>
            <strong>Numéro du ticket :</strong> {TIK_num}
          </Card.Text>
          <Card.Text>
            Notre équipe vous contactera bientôt pour plus de détails.
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default WPFormSuccess;