import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import { setStore } from '../api/axiosConfig'; // Importez la fonction setStore

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // Ajoutez les slices que vous souhaitez persister
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

// Injectez le store dans axiosConfig après l'initialisation
setStore(store);

export { store, persistor };