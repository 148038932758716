import axios from './axiosConfig';

export async function checkAuth() {
  try {
    const response = await axios.get('/auth/checkAuth', { withCredentials: true });
    return response.data.isAuthenticated;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function register(values) {
  try {
    const response = await axios.post('/auth/register', values);
    return response;
  } catch (err) {
    console.error('Error:', err.response.data.message);
    throw err;
  }
}

export async function login(values) {
  const response = await axios.post('/auth/login', values, { withCredentials: true });
  return response;
}

export async function sendResetPasswordEmail(email, type) {
  try {
    const response = await axios.post('auth/setPassMail', { mail: email, type });
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function resetPassword(token, password) {
  try {
    const response = await axios.post('/auth/resetPass', { token, password });
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export const logout = async () => {
  try {
    await axios.post('/auth/logout');
    localStorage.clear();
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

