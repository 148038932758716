// Fonction pour enregistrer le service worker
export function register() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
      navigator.serviceWorker.register('/service-worker.js').then(function (registration) {
      }, function (err) {
        console.log('ServiceWorker registration failed: ', err);
      });
    });
  }
}
