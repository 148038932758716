import axios from './axiosConfig';

export const fetchCotByCli = async (id) => {
    try {
        const response = await axios.get(`/cot/getCotByCli/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const fetchContactByCli = async (cliId, domId) => {
    try {
        const response = await axios.get(`/cot/getContact/${cliId}`, {
            params: {
                DOM_id: domId
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const postCot = async (values) => {
    try {
        const response = await axios.post(`/cot/`, values);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const updateCot = async (id, values) => {
    try {
        const response = await axios.put(`/cot/${id}`, values);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};