import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCotByCli, updateCot } from '../../api/cot'; // Assurez-vous d'importer les appels API nécessaires

const initialState = {
  contratsClient: {}, // Stocker les contrats par ID de client
  loading: false, // Indicateur de chargement
  error: null,    // Message d'erreur en cas d'échec
};

// Async thunk pour récupérer les contrats d'un client
export const fetchContratsByClient = createAsyncThunk('contrat/fetchContratsByClient', async (clientId) => {
  const response = await fetchCotByCli(clientId);
  return response; // Assurez-vous que la structure de la réponse correspond à vos besoins
});

// Async thunk pour mettre à jour un contrat
export const updateContrat = createAsyncThunk('contrat/updateContrat', async ({ id, contratData }) => {
  const response = await updateCot(id, contratData);
  return response; // Retourner l'ID du client et le contrat mis à jour
});

const cotSlice = createSlice({
  name: 'contrat',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchContratsByClient
      .addCase(fetchContratsByClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchContratsByClient.fulfilled, (state, action) => {
        state.loading = false;
        const contratsArray = action.payload;
        state.contratsClient = contratsArray.reduce((acc, contrat) => {
          acc[contrat.COT_id] = contrat;
          return acc;
        }, {});
      })
      .addCase(fetchContratsByClient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // updateContrat
      .addCase(updateContrat.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateContrat.fulfilled, (state, action) => {
        state.loading = false;
        const updatedContrat = action.payload.updatedCot;
        state.contratsClient[updatedContrat.COT_id] = updatedContrat;
      })
      .addCase(updateContrat.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default cotSlice.reducer;