import { combineReducers } from '@reduxjs/toolkit';
import errorReducer from './slices/errorSlice';
import authReducer from './slices/authSlice';
import cliReducer from './slices/cliSlice';
import cotReducer from './slices/cotSlice';

const rootReducer = combineReducers({
  error: errorReducer,
  auth: authReducer,
  cli: cliReducer,
  cot: cotReducer,
});

export default rootReducer;